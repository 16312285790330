const OPERATION_STATUS = [{
  value: 'done',
  label: '已完成',
}];
const TABLECOLUMN = [{
  label: '任务类型',
  width: 80,
  prop: 'taskType',
}, {
  label: '来源单据类型',
  width: 120,
  prop: 'documentSource',
}, {
  label: '来源单据号',
  prop: 'documentNumber',
  width: 100,
}, {
  label: '占用时间',
  prop: 'holdUpTime',
  width: 130,
}, {
  label: '释放时间',
  prop: 'releasingTime',
  width: 130,
}, {
  label: '作业状态',
  prop: 'operatingStatus',
  filter: true,
  arr: OPERATION_STATUS,
  width: 80,
}];

export {
  OPERATION_STATUS,
  TABLECOLUMN,
};
