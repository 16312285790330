<template>
  <pl-block>
    <PlForm
      v-model="formData"
      mode="search"
      :fields="serchFields"
      @submit="headleQuery"
      @reset="handleSearch"
    />
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :columns="TABLECOLUMN"
      :show-table-setting="true"
    >
      <template #tableHeaderRight>
        <nh-button
          :track="{
            trackName: '基础数据/仓库相关/容器管理/[新建]'
          }"
          @click="headleAdd"
        >
          新建
        </nh-button>
        <nh-button
          :track="{
            trackName: '基础数据/仓库相关/容器管理/[批量打印条码]'
          }"
        >
          批量打印条码
        </nh-button>
        <nh-button
          :track="{
            trackName: '基础数据/仓库相关/容器管理/[导入]'
          }"
        >
          导入
        </nh-button>
        <nh-button
          :track="{
            trackName: '基础数据/仓库相关/容器管理/[导出]'
          }"
        >
          导出
        </nh-button>
      </template>
      <template #action="{row}">
        <nh-button type="text" @click="headleEdit(row)">
          编辑
        </nh-button>
        <nh-button type="text" @click="headleView(row)">
          查看使用情况
        </nh-button>
      </template>
    </PlTable>
  </pl-block>
  <add-dialog ref="childAdd" @onSuccess="getTableData" />
  <edit-dialog ref="childEdit" @onSuccess="getTableData" />
  <use-condition-dailog ref="childViewUsage" />
</template>
<script>
import loadingMixin from '@thales/loading';
import EditDialog from './components/editDialog.vue';
import AddDialog from './components/addDialog.vue';
import UseConditionDailog from './components/useConditionDialog.vue';
import { TABLECOLUMN, serchFields } from './fileds';
import { warehouseContainerPage } from './api';

export default {
  name: 'StorageEquipment',
  components: {
    EditDialog,
    AddDialog,
    UseConditionDailog,
  },
  mixins: [loadingMixin],
  data() {
    return {
      TABLECOLUMN,
      serchFields,
      tableData: [],
      formData: {},
      loading: {
        getTableData: false,
      },
    };
  },
  watch: {
    pagination() {
      this.getTableData();
    },
  },
  created() {
    this.getTableData();
  },
  methods: {
    headleQuery() {
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await warehouseContainerPage(this.formData, pagination);
      this.tableData = resp.records;
      this.pagination.total = resp.total;
    },
    headleAdd() {
      this.$refs.childAdd.init();
    },
    headleEdit(row) {
      this.$refs.childEdit.init(row);
    },
    headleView(row) {
      this.$refs.childViewUsage.init(row);
    },

  },
};
</script>
<style scoped>
.action {
  padding: 15px 0;
}

.text-btn {
  cursor: pointer;
  color: #409eff;
}

.text-btn:not(:first-of-type) {
  padding-left: 8px;
}
</style>
