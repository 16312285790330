import { FormType } from '@/constant/form';

const DICTIONARY = {
  RENT: 'RENT',
};
const CONTAINER_TYPES = [{
  label: '周转箱',
  value: 'PACKAGE',
}, {
  label: '托盘',
  value: 'PALLET',
}, {
  label: '集货车',
  value: 'NORMAL_VEHICLE',
}, {
  label: '笼车',
  value: 'CAGE_VEHICLE',
}, {
  label: '一次性容器',
  value: 'ONCE',
}];
const USAGE_STATUS = [{
  label: '闲置',
  value: 'IDLE',
}, {
  label: '收货',
  value: 'BINDING',
}, {
  label: '在库',
  value: 'OCCUPYING',
}, {
  label: '占用',
  value: 'USING',
}];
const CONTAINER_ATTRIBUTE = [{
  label: '自有',
  value: 'BELONG',
}, {
  label: '租赁',
  value: 'RENT',
}];
const CONTAINER_STATUS = [{
  label: '正常',
  value: 'NORMAL',
}, {
  label: '退租',
  value: 'UNRENT',
}, {
  label: '破损',
  value: 'DAMAGE',
}];
const MIXTURE = [{
  label: '是',
  value: '是',
}, {
  label: '否',
  value: '否',
}];

function filterLabel(val, arr) {
  const filterArr = arr.find((item) => item.value === val);
  return filterArr ? filterArr.label : '';
}

const TABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '容器编码',
  prop: 'containerCode',
  minWidth: 120,
}, {
  label: '容器类型',
  prop: 'containerType',
  formatter: (row) => filterLabel(row.containerType, CONTAINER_TYPES),
  minWidth: 120,
}, {
  label: '使用状态',
  prop: 'useStatus',
  formatter: (row) => filterLabel(row.useStatus, USAGE_STATUS),

  minWidth: 120,
}, {
  label: '容器状态',
  prop: 'containerStatus',
  formatter: (row) => filterLabel(row.containerStatus, CONTAINER_STATUS),
  minWidth: 120,
}, {
  label: '规格',
  prop: 'specification',
  minWidth: 100,
}, {
  label: '承重(KG)',
  prop: 'capacityWeight',
  minWidth: 100,
}, {
  label: '容器重量(KG)',
  prop: 'weight',
  minWidth: 120,
}, {
  label: '容器属性',
  prop: 'containerAttr',
  formatter: (row) => filterLabel(row.containerAttr, CONTAINER_ATTRIBUTE),
  minWidth: 120,
}, {
  label: '所属方',
  prop: 'belongName',
  minWidth: 120,
}, {
  label: '是否允许混批次',
  prop: 'blendedBatch',
  formatter: (row) => filterLabel(row.blendedBatch, MIXTURE),
  minWidth: 120,
}, {
  label: '最大混批次数',
  prop: 'batchMax',
  minWidth: 120,
}, {
  label: '操作',
  prop: 'action',
  fixed: 'right',
  width: 240,
}];

export const serchFields = [
  {
    label: '容器编码',
    prop: 'containerCode',
    component: FormType.INPUT,
  }, {
    label: '容器类型',
    prop: 'containerType',
    component: FormType.SELECT,
    options: CONTAINER_TYPES,
  }, {
    label: '使用状态',
    prop: 'useStatus',
    component: FormType.SELECT,
    options: USAGE_STATUS,
  }, {
    label: '容器状态',
    prop: 'containerStatus',
    component: FormType.SELECT,
    options: CONTAINER_STATUS,
  },
];

export {
  DICTIONARY, CONTAINER_TYPES, USAGE_STATUS,
  MIXTURE, CONTAINER_STATUS, CONTAINER_ATTRIBUTE, TABLECOLUMN,
};
