<template>
  <div class="header-component">
    <nh-dialog
      v-model="dialogVisible"
      title="新建"
      :track="{
        name_zh: '基础数据/仓库相关/容器管理/弹窗-新建',
        trackName: $route.path
      }"
      @close="handleClose"
    >
      <el-form
        ref="ruleForm"
        :model="form"
        label-width="140px"
        :rules="rules"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="容器类型：" prop="containerType">
              <el-select v-model="form.containerType" placeholder="请选择">
                <el-option
                  v-for="item in CONTAINER_TYPES"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="容器前缀：" prop="prefix">
              <el-input v-model="form.prefix" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编号区间：" required>
              <el-row>
                <el-col :span="10">
                  <el-form-item prop="startCode">
                    <el-input v-model.number="form.startCode" />
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <div class="center">
                    至
                  </div>
                </el-col>
                <el-col :span="10">
                  <el-form-item prop="endCode">
                    <el-input v-model.number="form.endCode" />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="容器属性" prop="containerAttr">
              <el-select
                v-model="form.containerAttr"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in CONTAINER_ATTRIBUTE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="所属方："
              prop="belongName"
              :rules="[
                { required: DICTIONARY.RENT === form.containerAttr, message: '请输入所属方'},
              ]"
            >
              <el-input v-model="form.belongName" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="是否允许混批次：" prop="blendedBatch">
              <el-select
                v-model="form.blendedBatch"
                placeholder="请选择"
                @change="(val) => {form.batchMax = val === MIXTURE[0].value ? form.batchMax: null}"
              >
                <el-option
                  v-for="item in MIXTURE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="最大混批次数："
              prop="batchMax"
              :rules="[
                { required: form.blendedBatch === MIXTURE[0].value,
                  message: '请输入最大混批次数', trigger: 'blur' },
                { type: 'number', message: '必须为数字值', trigger: 'blur' },
                { validator: regMinus, trigger: 'blur' },
              ]"
            >
              <el-input
                v-model.number="form.batchMax"
                :disabled="form.blendedBatch === MIXTURE[1].value"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <p><i class="el-icon-warning-outline icon-70dfe5" />容器号生成规则：容器前缀+“-”+编号。例：TP-001</p>
          <div class="btn">
            <nh-button
              type="primary"
              :loading="loading.headleSubmit"
              @click="headleSubmit"
            >
              确 定
            </nh-button>
            <nh-button @click="close">
              取 消
            </nh-button>
          </div>
        </div>
      </template>
    </nh-dialog>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import {
  DICTIONARY, CONTAINER_TYPES, USAGE_STATUS, MIXTURE, CONTAINER_STATUS, CONTAINER_ATTRIBUTE,
} from '../fileds';
import { batchInsert } from '../api';

export default {
  name: 'AddDialog',
  mixins: [loadingMixin],
  emits: ['onSuccess'],
  data() {
    const validateStartCode = (rule, value, callback) => {
      if (!Number.isInteger(value)) {
        return callback(new Error('请输入数字值'));
      }
      if (value < 0) {
        return callback(new Error('不能为负数'));
      }
      if (this.form.endCode) {
        this.$refs.ruleForm.validateField('endCode');
      }
      return callback();
    };
    const validateEndCode = (rule, value, callback) => {
      if (!Number.isInteger(value)) {
        return callback(new Error('请输入数字值'));
      }
      if (this.form.startCode > value) {
        return callback(new Error('大于第一次输入'));
      }
      return callback();
    };
    return {
      DICTIONARY,
      CONTAINER_TYPES,
      USAGE_STATUS,
      MIXTURE,
      CONTAINER_STATUS,
      CONTAINER_ATTRIBUTE,
      dialogVisible: false,
      loading: {
        headleSubmit: false,
      },
      theirs: [],
      form: {
        containerType: null,
        prefix: null,
        startCode: null,
        endCode: null,
        containerAttr: null,
        belongName: null,
        blendedBatch: null,
        batchMax: null,
      },
      rules: {
        containerType: [
          { required: true, message: '请选择容器类型', trigger: 'change' },
        ],
        startCode: [
          { validator: validateStartCode, trigger: 'blur' },
        ],
        endCode: [
          { validator: validateEndCode, trigger: 'blur' },
        ],
        containerAttr: [
          { required: true, message: '请选择设备属性', trigger: 'change' },
        ],
        blendedBatch: [
          { required: true, message: '是否允许混批次', trigger: 'change' },
        ],
      },
    };
  },
  watch: {
    'form.containerType': {
      handler(newVal) {
        this.form.prefix = newVal;
      },
    },
  },
  methods: {
    init() {
      this.dialogVisible = true;
    },
    regMinus(rule, value, callback) {
      if (value < 0) {
        return callback(new Error('不能为负数'));
      }
      return callback();
    },
    async headleSubmit() {
      await this.$refs.ruleForm.validate();
      await batchInsert(this.form);
      this.$message({
        type: 'success',
        message: '添加成功!',
      });
      this.$emit('onSuccess');
      this.close();
    },
    close() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.$refs.ruleForm.resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-70dfe5 {
  color: #70dfe5;
}

.center {
  text-align: center;
}

.dialog-footer {
  text-align: left;

  .btn {
    text-align: right;
  }
}
</style>
