import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 新增
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11194
 */
export function containerInsert(data) {
  return wmsPlusHttp.post('/warehouse_foundation/container/insert', data);
}
/**
 * @description: 批量新增
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11216
 */
export function batchInsert(data) {
  return wmsPlusHttp.post('/warehouse_foundation/container/batch/insert', data);
}
/**
 * @description: 修改
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11198
 */
export function containerUpdate(data) {
  return wmsPlusHttp.post('/warehouse_foundation/container/update', data);
}
/**
 * @description: 根据ID获取
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11200
 */
export function getWarehouseContainer(params) {
  const { id } = { ...params };
  return wmsPlusHttp.get(`/warehouse_foundation/container/get?id=${id}`);
}
/**
 * @description: 根据ID删除
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11202
 */
export function warehouseContainerDelete(params) {
  const { id } = { ...params };
  return wmsPlusHttp.get(`/warehouse_foundation/container/delete?id=${id}`);
}
/**
 * @description: 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11204
 */

export function warehouseContainerPage(data, params = {
  page: 1,
  size: 10,
}) {
  return wmsPlusHttp.post('/warehouse_foundation/container/page', data, {
    params,
  });
}
/**
 * @description: 导出容器列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11292
 */

/**
 * @description: 上传
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11294
 */
