<template>
  <div class="header-component">
    <el-dialog
      v-model="dialogVisible"
      title="编辑"
      :track="{
        name_zh: '基础数据/仓库相关/容器管理/弹窗-编辑',
        trackName: $route.path
      }"
      @close="handleClose"
    >
      <el-form
        ref="ruleForm"
        :model="form"
        label-width="140px"
        :rules="rules"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="容器编号：" prop="containerCode">
              <el-input v-model="form.containerCode" :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="容器类型：" prop="containerType">
              <el-select v-model="form.containerType" placeholder="请选择">
                <el-option
                  v-for="item in CONTAINER_TYPES"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否允许混批次：" prop="blendedBatch">
              <el-select v-model="form.blendedBatch" placeholder="请选择">
                <el-option
                  v-for="item in MIXTURE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="容器状态：" prop="containerStatus">
              <el-select v-model="form.containerStatus" placeholder="请选择">
                <el-option
                  v-for="item in CONTAINER_STATUS"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="容器属性" prop="containerAttr">
              <el-select
                v-model="form.containerAttr"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in CONTAINER_ATTRIBUTE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="所属方："
              prop="belongName"
              :rules="[
                { required: DICTIONARY.RENT === form.containerAttr, message: '请输入所属方'},
              ]"
            >
              <el-input v-model="form.belongName" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <nh-button
            type="primary"
            :loading="loading.headleSubmit"
            @click="headleSubmit"
          >
            确 定
          </nh-button>
          <nh-button @click="close">
            取 消
          </nh-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import {
  DICTIONARY, CONTAINER_TYPES, USAGE_STATUS, MIXTURE, CONTAINER_STATUS, CONTAINER_ATTRIBUTE,
} from '../fileds';
import { containerUpdate } from '../api';

export default {
  name: 'EditDialog',
  mixins: [loadingMixin],
  emits: ['onSuccess'],
  data() {
    return {
      DICTIONARY,
      CONTAINER_TYPES,
      USAGE_STATUS,
      MIXTURE,
      CONTAINER_STATUS,
      CONTAINER_ATTRIBUTE,
      edit: false,
      dialogVisible: false,
      loading: {
        headleSubmit: false,
      },
      theirs: [],
      form: {
        containerCode: null,
        containerType: null,
        blendedBatch: null,
        containerStatus: null,
        containerAttr: null,
        belongName: null,
      },
      rules: {
        containerCode: [
          { required: true, message: '请输入容器编号', trigger: 'blur' },
        ],
        containerType: [
          { required: true, message: '请选择容器类型', trigger: 'change' },
        ],
        blendedBatch: [
          { required: true, message: '是否允许混批次', trigger: 'change' },
        ],
        containerStatus: [
          { required: true, message: '请选择容器状态', trigger: 'change' },
        ],
        containerAttr: [
          { required: true, message: '请选择容器属性', trigger: 'change' },
        ],
      },
    };
  },
  methods: {
    init(data) {
      this.dialogVisible = true;
      this.$nextTick(() => {
        if (data) {
          this.form = { ...data };
        }
      });
    },
    async headleSubmit() {
      await this.$refs.ruleForm.validate();
      await containerUpdate(this.form);
      this.$message({
        type: 'success',
        message: '修改成功!',
      });
      this.$emit('onSuccess');
      this.close();
    },
    close() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.$refs.ruleForm.resetFields();
    },
  },
};
</script>
